<section class="section team-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Our Founders</h2>
                    <p class="d-none d-sm-block mt-4">Palm Shop was built for Guyanese by Guyanese.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                <!-- Single Team -->
                <div class="single-team text-center m-2 m-lg-0">
                    <!-- Team Thumb -->
                    <div class="team-thumb d-inline-block position-relative overflow-hidden">
                        <img class="rounded-circle" src="assets/img/avatar_1.png" alt="Joel Hinds">
                        <!-- Team Overlay -->
                        <div class="team-overlay bg-overlay rounded-circle">
                            <h4 class="team-name text-white">Joel Hinds</h4>
                            <h5 class="team-post text-white mt-2 mb-3">Founder &amp; CEO</h5>
                            <!-- Team Icons -->
                            <div class="team-icons">
                                <a class="p-2" href="#"><i class="fab fa-facebook-f"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-twitter"></i></a>
                                <a class="p-2" target="_blank" href="https://www.linkedin.com/in/joel-hinds-13664a216/"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                <!-- Single Team -->
                <div class="single-team text-center m-2 m-lg-0">
                    <!-- Team Thumb -->
                    <div class="team-thumb d-inline-block position-relative overflow-hidden">
                        <img class="rounded-circle" src="assets/img/avatar_2.png" alt="Keon Hestick">
                        <!-- Team Overlay -->
                        <div class="team-overlay bg-overlay rounded-circle">
                            <h4 class="team-name text-white">Keon Hestick</h4>
                            <h5 class="team-post text-white mt-2 mb-3">Co-Founder &amp; Managing Director</h5>
                            <!-- Team Icons -->
                            <div class="team-icons">
                                <a class="p-2" href="#"><i class="fab fa-facebook-f"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-twitter"></i></a>
                                <a class="p-2" href="#"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                <!-- Single Team -->
                <div class="single-team text-center m-2 m-lg-0">
                    <!-- Team Thumb -->
                    <div class="team-thumb d-inline-block position-relative overflow-hidden">
                        <img class="rounded-circle" src="https://res.cloudinary.com/kalpa-services/image/upload/c_scale,q_100,w_320/v1635200835/palmshop-website/FB_IMG_1584213472777_dxstlt.webp" alt="Carl Handy">
                        <!-- Team Overlay -->
                        <div class="team-overlay bg-overlay rounded-circle">
                            <h4 class="team-name text-white">Carl Handy</h4>
                            <h5 class="team-post text-white mt-2 mb-3">Co-Founder &amp; CTO</h5>
                            <!-- Team Icons -->
                            <div class="team-icons">
                                <a class="p-2" target="_blank" href="https://twitter.com/carlhandy_"><i class="fab fa-twitter"></i></a>
                                <a class="p-2" target="_blank" href="https://instagram.com/carlhandy_"><i class="fab fa-instagram"></i></a>
                                <a class="p-2" target="_blank" href="https://linkedin.com/in/carlHandy"><i class="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>