<section id="pricing" class="section price-plan-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Business Service Fee</h2>
                    <p class="d-none d-sm-block mt-4">Our pricing is transactional, so we don't get paid unless you do!</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <!-- Price Plan Wrapper -->
            <div class="price-plan-wrapper">
                <!-- Single Price Plan -->
                <div class="single-price-plan col-md-4">
                    <!-- Plan Thumb -->
                    <div class="plan-thumb mx-auto my-4">
                        <i style="font-size: 40px; color: white" class="fas fa-hand-holding-usd"></i>
                    </div>
                    <!-- Price Header -->
                    <div class="price-header d-flex flex-column align-items-center pb-2">
                        <h4 class="plan-title text-uppercase pb-3">
                            UP TO $2,000
                        </h4>
                        <div class="plan-cost">
                            <span class="plan-price">$50</span>
                            <span class="plan-type text-uppercase">/per transaction</span>
                        </div>
                    </div>
                </div>
                <div class="single-price-plan col-md-4">
                    <!-- Plan Thumb -->
                    <div class="plan-thumb mx-auto my-4">
                        <i style="font-size: 40px; color: white" class="fas fa-hand-holding-usd"></i>
                    </div>
                    <!-- Price Header -->
                    <div class="price-header d-flex flex-column align-items-center pb-2">
                        <h4 class="plan-title text-uppercase pb-3">
                            UP TO $10,000
                        </h4>
                        <div class="plan-cost">
                            <span class="plan-price">$150</span>
                            <span class="plan-type text-uppercase">/per transaction</span>
                        </div>
                    </div>
                </div>
                <div class="single-price-plan col-md-4">
                    <!-- Plan Thumb -->
                    <div class="plan-thumb mx-auto my-4">
                        <i style="font-size: 40px; color: white" class="fas fa-hand-holding-usd"></i>
                    </div>
                    <!-- Price Header -->
                    <div class="price-header d-flex flex-column align-items-center pb-2">
                        <h4 class="plan-title text-uppercase pb-3">
                           MORE THAN $10,000
                        </h4>
                        <div class="plan-cost">
                            <span class="plan-price">$250</span>
                            <span class="plan-type text-uppercase">/per transaction</span>
                        </div>
                    </div>
                </div>
   
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <span class="d-block text-center mt-5">Still have questions? <a href="#">Contact Us</a></span>
                    <!-- Appia Accordion -->
                    <div class="accordion pt-5" id="appia-accordion">
                        <div class="row">
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn" type="button" data-toggle="collapse" data-target="#collapseOne">
                                                Does my business need to be registered?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" class="collapse show" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Yes. Only businesses registered or licensed to operate in Guyana are allowed on the platform.
                                            <br/><br/>
                                                <b>The following documents are needed:</b>
                                                <br/>
                                                > Business Registration <br/>
                                                > Valid ID (National ID, Passport, Driver's License)<br/>
                                                > TIN Certificate
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
                                                Is Palm Shop free?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Palm Shop is free to use for buyers, they only pay for the cost of their item and the delivery fee. 
                                            Businesses will pay a small fee per transaction.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseThree">
                                               Are transactions done via debit/card?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            No. All transactions are facilitated through Mobile Money Guyana (MMG) so both buyers and sellers would need an account.<br>
                                            <a target="_blank" href="https://www.mmg.gy/registration/">
                                                <button style="margin-top: 10px;" class="btn btn-bordered">MMG Registration</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6">
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour">
                                                What if my business already offers delivery?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                           Not a problem. We can link your riders to your business so only they'll be notified of new deliveries.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn" type="button" data-toggle="collapse" data-target="#collapseFive">
                                                How do I get paid?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" class="collapse show" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            Funds from purchases goes directly to your business' MMG account when the customer hits checkout.
                                        </div>
                                    </div>
                                </div>
                                <!-- Single Accordion Item -->
                                <div class="card my-2">
                                    <!-- Card Header -->
                                    <div class="card-header">
                                        <h2 class="mb-0">
                                            <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSix">
                                                Does my business need any special hardware?
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSix" class="collapse" data-parent="#appia-accordion">
                                        <!-- Card Body -->
                                        <div class="card-body">
                                            No. A smartphone, tablet and solid internet connection is all that's required.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>