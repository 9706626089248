<header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
    <div class="container position-relative">
        <a class="navbar-brand" href="/">
            
            <img style="width: 245px;" class="navbar-brand-regular" 
            src="https://res.cloudinary.com/kalpa-services/image/upload/c_crop,h_170,w_800/v1635195000/palmshop-website/AM-JKLUKOGMnvBdqOBvFBuuOlpWomuyducROR3ZKumre2nf4gf8s72U5R0ICoUOIk5SyWfBzU2v4UVGog2zweTQ0JwNj23POw8qowzH35Pmch7H3E7NldLcH1ZsHdtIuWqphuwXf5BBkid4Nu2TQqxjLngSZTQ_s937-no_ighxu4.webp" alt="palmshop-logo-white">
            <img class="navbar-brand-sticky"  style="width: 140px;"
            src="https://res.cloudinary.com/kalpa-services/image/upload/c_crop,h_170,w_700/v1635195000/palmshop-website/AM-JKLWZLMhCKpirCPvhKvDmdC773AzMUTfkXLKWhOc0_9cd46K2Bbml9BHvBFP1K1HM7ai_L-v98lkFZd0Ktd8AaFZstirZanecxbOfKnm_LWMsRMTrnbv_HYWuMKK8QkPBV79-4IqXsWMBfRO441aPhCknrg_w938-h937-no_leqdey.webp" alt="palmshop-logo">
        </a>
        <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-inner">
            <!--  Mobile Menu Toggler -->
            <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <nav>
                <ul class="navbar-nav" id="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#home">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#features">How it works</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll  " href="#">Resource Center<i style="margin-left: 5px;" class="fa fa-caret-down"></i></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#pricing">Pricing</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#contact">Contact</a>
                    </li>
                    <li class="nav-item">
                        <a target="_blank" href="https://console.palmshop.gy/register">
                            <button style="margin-top: 15px; margin-left: 15px;" class="scroll btn register-btn">Register Business</button>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>