<footer class="section footer-area footer-bg overflow-hidden">
    <!-- Footer Top -->
    <div class="footer-top ptb_100">
        <div class="container">
            <div class="row">
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Logo -->
                        <a class="navbar-brand" href="/">
                            <img class="logo" src="https://res.cloudinary.com/kalpa-services/image/upload/c_crop,h_170,w_700/v1635195000/palmshop-website/AM-JKLUKOGMnvBdqOBvFBuuOlpWomuyducROR3ZKumre2nf4gf8s72U5R0ICoUOIk5SyWfBzU2v4UVGog2zweTQ0JwNj23POw8qowzH35Pmch7H3E7NldLcH1ZsHdtIuWqphuwXf5BBkid4Nu2TQqxjLngSZTQ_s937-no_ighxu4.webp" alt="palmshop">
                        </a>
                        <p class="text-white mt-2 mb-3">Shopping made simple.</p>
                        <!-- Social Icons -->
                        <div class="social-icons d-flex">
                            <a class="facebook" href="#">
                                <i class="fab fa-facebook-f"></i>
                                <i class="fab fa-facebook-f"></i>
                            </a>
                            <a class="twitter" href="#">
                                <i class="fab fa-twitter"></i>
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a class="instagram" href="#">
                                <i class="fab fa-instagram"></i>
                                <i class="fab fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Useful Links</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" target="_blank" href="https://www.mmg.gy/faqs/">MMG FAQs</a><i style="margin-left: 5px; color: #82cde5" class="fas fa-external-link-square-alt"></i></li>
                            <li class="py-2"><a class="text-white" target="_blank" href="https://www.mmg.gy/lead-generation/">MMG Business Registration</a><i style="margin-left: 5px; color: #82cde5" class="fas fa-external-link-square-alt"></i></li>
                            <li class="py-2"><a class="text-white" target="_blank" href="https://www.mmg.gy/register-customer/">MMG Customer Registration</a><i style="margin-left: 5px; color: #82cde5" class="fas fa-external-link-square-alt"></i></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2"> Resource Center</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" href="#">FAQs</a></li>
                            <li class="py-2"><a class="text-white" href="#">Support</a></li>
                            <li class="py-2"><a class="text-white" href="#">Privacy Policy</a></li>
                            <li class="py-2"><a class="text-white" href="#">Terms &amp; Conditions</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Download</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" href="#">iOS</a><i style="margin-left: 5px; color: #97979c;" class="fab fa-apple"></i></li>
                            <li class="py-2"><a class="text-white" href="#">Android</a><i style="margin-left: 5px; color: #a4c639;" class="fab fa-android"></i></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Shape Top -->
        <div class="shape-top">
            <svg fill="#f6f9fe" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2600 131.1" preserveAspectRatio="none">
                <path d="M0 0L2600 0 2600 69.1 0 0z"></path>
                <path style="opacity:0.5" d="M0 0L2600 0 2600 69.1 0 69.1z"></path>
                <path style="opacity:0.25" d="M2600 0L0 0 0 130.1 2600 69.1z"></path>
            </svg>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left text-white">&copy; Copyright 2021 Palm Shop All rights reserved.</div>
                        <!-- Copyright Right -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>