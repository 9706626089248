<section class="start-free-area style-two">
    <div class="container">
        <div class="col-12">
            <!-- Start Free Content -->
            <div class="start-free-content text-center shadow-lg" data-aos="fade-up" data-aos-duration="800">
                <!-- Start Free Content -->
                <div class="start-free-text">
                    <strong>Try Appia for free!</strong>
                    <h2 class="mt-2 mb-3">Appia is available for all devices</h2>
                    <p class="start-free-details d-none d-lg-block">Thousand's of customer's loves Appia, consectetur adipisicing elit. Voluptatibus suscipit explicabo unde sit atque exercitationem delectus qui ipsam, at. Vitae veritatis officia quidem commodi repudiandae illum cum, quisquam voluptatibus quaerat.</p>
                    <p class="start-free-details d-block d-lg-none">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Omnis dolor maiores, eius eveniet deleniti dolorem fugiat aliquid veritatis Suscipit, quibusdam.</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons d-flex justify-content-center">
                        <a href="#">
                            <img src="assets/img/google-play.svg" alt="">
                        </a>
                        <a href="#">
                            <img src="assets/img/app-store.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>