<section class="section download-area bg-img bg-overlay ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8 col-lg-7">
                <!-- Download Text -->
                <div class="download-text">
                    <h2 class="text-white">Download Palm Shop</h2>
                    <p class="text-white my-3 d-none d-sm-block">A simpler way to shop starts here. Download Palm Shop now!</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons d-flex">
                        <a href="#">
                            <img src="assets/img/google-play.svg" alt="palmshop android download">
                        </a>
                        <a href="#">
                            <img src="assets/img/app-store.svg" alt="palmshop ios download">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Download Thumb -->
    <div class="download-thumb pos-abs-top-right d-none d-md-block">
        <img src="/assets/img/download_thumb.png">
    </div>
</section>