<div class="homepage-4">
    <app-scrollup></app-scrollup>
    <div class="main">
        <app-header-two></app-header-two>
        <app-hero-four></app-hero-four>
        <app-branding></app-branding>
        <app-content-seven></app-content-seven>
        <app-content-eight></app-content-eight>
        <app-screenshot-two></app-screenshot-two>
        <app-feature-one></app-feature-one>
        <app-pricing-two></app-pricing-two>
        <!-- <app-review></app-review> -->
        <!-- <app-team-one></app-team-one> -->
        <app-download-two></app-download-two>
        <app-contact></app-contact>
        <app-footer-four></app-footer-four>
    </div>
</div>